import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

const firebaseConfig = {
  apiKey: "AIzaSyAwdCiO8TlvMAyZzO7xjhgroaz_WhtM6ZI",
  authDomain: "lippulaiva-lounas.firebaseapp.com",
  projectId: "lippulaiva-lounas",
  storageBucket: "lippulaiva-lounas.appspot.com",
  messagingSenderId: "111321602958",
  appId: "1:111321602958:web:f563042b80771d439422df",
  measurementId: "G-YZ8Z0FG4SP",
};

const app = initializeApp(firebaseConfig);
